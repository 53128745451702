import './base'
import { AdminBox, mountOnId } from 'shared'
import { NewsAdminBox } from 'ppp/news_admin_box'
import { NewsList, ProjectCtaBar } from 'news'

document.addEventListener('DOMContentLoaded', () => {
  mountOnId(NewsList, 'news-list')
  mountOnId(ProjectCtaBar, 'project-cta-bar')
  mountOnId(AdminBox, 'admin-box')
  mountOnId(NewsAdminBox, 'project-news-admin-box')
})

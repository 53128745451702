import React from 'react'
import classNames from 'classnames'
import styles from './news_admin_box.module.css'
import { AdminBox, QuickAccessButton } from 'shared'

type NewsAdminBoxProps = {
  manageProjectUrl: string
  writeNewsUrl: string
}

export const NewsAdminBox = ({ manageProjectUrl, writeNewsUrl }: NewsAdminBoxProps): JSX.Element | null => {
  return (
    <div className="container">
      <div className="row pb-4">
        <div className="col mt-4">
          <AdminBox variant="dark" customTitle={i18n.t('projects.admin_boxes.header_title')} quickAccessBox withShadow>
            <div className="container">
              <div className={classNames(styles.container, 'flex flex-wrap items-center justify-center')}>
                <QuickAccessButton type="write_news" url={writeNewsUrl} />
                <QuickAccessButton type="all_settings" url={manageProjectUrl} />
              </div>
            </div>
          </AdminBox>
        </div>
      </div>
    </div>
  )
}
